import ng from 'angular';
import acpContactUsModule from 'apps/contact-us';
import acpComponentCardownerDomain from 'components/cardowner-domain';
import acpFeePlanDomain from 'components/feeplan-domain';
import acpComponentPanCvcCheck from 'components/pan-cvc-check';
import acpCore from 'core';
import acpDctDisbursementAmountComponent from './acp-dct-disbursement-amount-component';
import acpDctDisbursementCardInfoComponent from './acp-dct-disbursement-card-info-component';
import acpDctDisbursementIntroComponent from './acp-dct-disbursement-intro-component';
import { AcpDctDisbursementService } from './acp-dct-disbursement-service';
import acpDctDisbursementTermsComponent from './acp-dct-disbursement-terms-component';
import acpDctDisbursementContent from './dct-disbursement-content.yml';

import acpDctDisbursementDetailsComponent from './acp-dct-disbursement-details-component';
import './styles/${theme}/core.scss';

export const acpDctDisbursementComponentModule = ng
  .module('acp.component.dct-disbursement', [
    acpCore.name,
    acpComponentCardownerDomain.name,
    acpComponentPanCvcCheck.name,
    acpFeePlanDomain.name,
    acpContactUsModule.name
  ])
  .service('acpDctDisbursementService', AcpDctDisbursementService)
  .component('acpDctDisbursementIntro', acpDctDisbursementIntroComponent)
  .component('acpDctDisbursementTerms', acpDctDisbursementTermsComponent)
  .component('acpDctDisbursementCardInfo', acpDctDisbursementCardInfoComponent)
  .component('acpDctDisbursementAmount', acpDctDisbursementAmountComponent)
  .component('acpDctDisbursementDetails', acpDctDisbursementDetailsComponent)
  .run((contentSectionCache) => {
    'ngInject';
    contentSectionCache.put(
      'components/dct-disbursement',
      acpDctDisbursementContent
    );
  });

// @bazel-dep: apps/debit-card-transfers/components/debit-card-transfers
