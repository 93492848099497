import {
  DCTErrorType,
  DebitCardTransfersLimitResponse,
  DebitCardTransfersSaveCardRequest,
  DebitCardTransfersSaveCardResponse,
  DebitCardTransfersTransferRequest,
  DebitCardTransfersTransferResponse
} from 'apps/debit-card-transfers/components/debit-card-transfers';
import { AcpFeeplanModel, FeePlanResponse } from 'components/feeplan-domain';

import { AcpApiBrandingDomainClient, Program } from 'core';
import { acpDctDisbursementAmountComponent } from './acp-dct-disbursement-amount-component';
import { acpDctDisbursementCardInfoComponent } from './acp-dct-disbursement-card-info-component';
import { acpDctDisbursementDetailsComponent } from './acp-dct-disbursement-details-component';
import { acpDctDisbursementErrorComponent } from './acp-dct-disbursement-error-component';
import { acpDctDisbursementTermsComponent } from './acp-dct-disbursement-terms-component';

export class AcpDctDisbursementService {
  saveCardPush = this.webapiResource<
    DebitCardTransfersSaveCardRequest,
    DebitCardTransfersSaveCardResponse
  >({
    method: 'POST',
    path: '/v2/debitcardtransfer/push/card',
    format: 'json'
  });

  getPushLimit = this.webapiResource<void, DebitCardTransfersLimitResponse>({
    method: 'GET',
    path: '/v2/debitcardtransfer/push/limit'
  });

  pushTransfer = this.webapiResource<
    DebitCardTransfersTransferRequest,
    DebitCardTransfersTransferResponse
  >({
    method: 'POST',
    path: '/v2/debitcardtransfer/push/transfer',
    format: 'json'
  });

  private transferInPageFlowConfig = {
    flowTitle: 'Debit Card Transfer',
    controllerAs: 'vm',
    suppressBackButton: false
  };

  private _cardId: string;
  private _last4Pan: number;
  private _fees: FeePlanResponse['fees'];
  private _pushLimit: string;
  private _amount: number;
  private _confirmNum: string;
  private _transferLoading: boolean;
  private _account: string;
  private branding: Program = null;

  constructor(
    public nsInPageFlow: any,
    private webapiResource: nsWebclient.WebapiResourceService,
    private acpFeeplanDomainModel: AcpFeeplanModel,
    private acpCoreDispatcher: any,
    private acpSimpleAccountModel: any,
    private acpApiBrandingDomainClient: AcpApiBrandingDomainClient,
    private NS_PROGRAM_TYPES: any
  ) {
    'ngInject';
    this.getLimit();
    this.fetchFees();
    this.acpApiBrandingDomainClient.getApiBranding().then((branding) => {
      this.branding = branding;
      this.getInternalAccounts();
    });
  }

  get cardId(): string {
    return this._cardId;
  }

  set cardId(value: string) {
    this._cardId = value;
  }

  get last4Pan(): number {
    return this._last4Pan;
  }

  set last4Pan(value: number) {
    this._last4Pan = value;
  }

  get amount(): number {
    return this._amount;
  }

  set amount(value: number) {
    this._amount = value;
  }

  get confirmNum(): string {
    return this._confirmNum;
  }

  set confirmNum(value: string) {
    this._confirmNum = value;
  }

  get fees(): FeePlanResponse['fees'] {
    return this._fees;
  }
  set fees(value: FeePlanResponse['fees']) {
    this._fees = value;
  }

  get transferLoading(): boolean {
    return this._transferLoading;
  }

  set transferLoading(value: boolean) {
    this._transferLoading = value;
  }

  get pushLimit(): string {
    return this._pushLimit;
  }
  set pushLimit(value: string) {
    this._pushLimit = value;
  }

  get account(): string {
    return this._account;
  }
  set account(value: string) {
    this._account = value;
  }

  enterTerms(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDctDisbursementTermsComponent
    };

    this.nsInPageFlow.open(config);
  }

  flowIntoTerms(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDctDisbursementTermsComponent
    };

    this.nsInPageFlow.push(config);
  }

  enterCardDetails(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDctDisbursementCardInfoComponent
    };

    this.nsInPageFlow.open(config);
  }

  flowIntoCardDetails(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDctDisbursementCardInfoComponent
    };

    this.nsInPageFlow.push(config);
  }

  flowIntoErrorPage(
    errorType?: DCTErrorType,
    dontSuppressBackBtn?: boolean
  ): void {
    const errorComponent = acpDctDisbursementErrorComponent(errorType);
    const config = {
      flowTitle: 'Debit Card Transfer',
      controllerAs: 'vm',
      suppressBackButton: !dontSuppressBackBtn,
      ...errorComponent
    };

    this.nsInPageFlow.push(config);
  }

  flowIntoAmountPage(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDctDisbursementAmountComponent
    };

    this.nsInPageFlow.push(config);
  }

  async getLimit(): Promise<void> {
    const pushLimit: void | DebitCardTransfersLimitResponse = await this.getPushLimit().catch(
      (error) => {
        this.flowIntoErrorPage();
      }
    );

    if (pushLimit) {
      this._pushLimit = '' + pushLimit.limit / 100;
    }
  }

  async getInternalAccounts(): Promise<any[]> {
    return this.acpSimpleAccountModel.get().then((data) => {
      this.account = data.account;
      [this.account] = this.addBrandingToAccounts([this.account]);
    });
  }

  async fetchFees(): Promise<void> {
    const {
      plan: { fees }
    } = await this.acpFeeplanDomainModel.get();
    this.fees = fees;
  }

  async closeFlow(): Promise<void> {
    return await this.nsInPageFlow.close();
  }

  flowIntoConfirmPage(): void {
    // Confirmation page stuff goes here
  }

  flowIntoTransferDetails(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDctDisbursementDetailsComponent
    };

    this.nsInPageFlow.push(config);
  }

  getTransferErrorType(webapiError: string): DCTErrorType {
    let errorType: DCTErrorType;
    switch (webapiError) {
      case 'insufficient_funds':
      case 'service.provider.failure':
        errorType = DCTErrorType.CONTACT_CS;
        break;
      case 'service.provider.timeout':
      case 'request.timeout':
        errorType = DCTErrorType.TIMEOUT;
        break;
      default:
        errorType = DCTErrorType.UNSPECIFIED;
    }
    return errorType;
  }

  async doTransfer(): Promise<void> {
    const trRequest: DebitCardTransfersTransferRequest = {
      card_id: this.cardId,
      amount: this.amount * 100
    };
    this.transferLoading = true;

    try {
      const trResponse: DebitCardTransfersTransferResponse = await this.pushTransfer(
        trRequest
      );

      this.confirmNum = trResponse.confirmation_number;
      this.acpCoreDispatcher.history.refresh.requested.emit(); // to refresh numbers on a dashboard
      this.flowIntoConfirmPage();
    } catch (error) {
      if (
        !error.error &&
        error._errors &&
        error._errors.length &&
        error._errors.indexOf('request.timeout') !== -1
      ) {
        // handle timeout error from acpApiResourceTimeoutInterceptor
        error.error = 'request.timeout';
      }
      const errorType: DCTErrorType = this.getTransferErrorType(error.error);
      this.flowIntoErrorPage(errorType);
    } finally {
      this.cardId = null;
      this.transferLoading = false;
    }
  }

  private addBrandingToAccounts(internalAccounts: any[]): any[] {
    const programType: string = this.branding.type;
    const brandName: string = this.branding.brand.name;
    return internalAccounts.map((acc) => {
      if (
        programType === this.NS_PROGRAM_TYPES.CONSUMER_DDA ||
        programType === this.NS_PROGRAM_TYPES.BAW_DDA ||
        programType === this.NS_PROGRAM_TYPES.NETSPEND_DISBURSEMENT
      ) {
        acc.name = /account/i.test(brandName)
          ? brandName
          : brandName + ' Account';
      } else if (programType === this.NS_PROGRAM_TYPES.GPR) {
        acc.name = /card/i.test(brandName)
          ? brandName + ' Account'
          : brandName + ' Card Account';
      }
      return acc;
    });
  }
}
