import ng from 'angular';
import {
  DCTErrorType,
  DebitCardTransfersCardFormInput,
  DebitCardTransfersSaveCardRequest,
  DebitCardTransfersSaveCardResponse
} from 'apps/debit-card-transfers/components/debit-card-transfers';

import { AcpDctDisbursementService } from './acp-dct-disbursement-service';
import acpDctDisbursementCardInfoTemplate from './templates/acp-dct-disbursement-card-info.html';

export class AcpDctDisbursementCardInfoCtrl
  implements nsUtils.NsComponentController {
  public isSubmitting: boolean = false;

  private _dctCardForm: ng.IFormController;
  private _cardInputs: DebitCardTransfersCardFormInput;

  constructor(
    private acpConfigurationService: any,
    private acpDctDisbursementService: AcpDctDisbursementService
  ) {
    'ngInject';
    // See GB-761. Currently, life-cycle hooks do not work for nsInPageFlow.
  }

  // TODO: AVG-2692
  public canScanCard(): boolean {
    return this.acpConfigurationService.getActivationShowScanCardRegistration();
  }

  public isInvalid(): boolean {
    return !this.dctCardForm || this.dctCardForm.$invalid;
  }

  public async submitCard(): Promise<void> {
    if (this.isSubmitting || this.isInvalid()) {
      return;
    }

    this.isSubmitting = true;
    this.acpDctDisbursementService.last4Pan = this.cardInputs.pan % 10000;

    try {
      const saveCardResp: DebitCardTransfersSaveCardResponse = await this.acpDctDisbursementService.saveCardPush(
        this.getSaveCardRequest()
      );
      this.acpDctDisbursementService.cardId = saveCardResp.id;
      this.acpDctDisbursementService.flowIntoAmountPage();
    } catch (error) {
      const errorType: DCTErrorType = this.getErrorType(error.error);
      this.acpDctDisbursementService.flowIntoErrorPage(errorType, true);
    } finally {
      this.isSubmitting = false;
    }
  }

  private getErrorType(webapiError: string): DCTErrorType {
    let errorType: DCTErrorType;
    switch (webapiError) {
      case 'service.provider.failure':
        errorType = DCTErrorType.CONTACT_CS;
        break;
      default:
        errorType = DCTErrorType.UNSPECIFIED;
    }
    return errorType;
  }

  private getSaveCardRequest(): DebitCardTransfersSaveCardRequest {
    const saveCardRequest: DebitCardTransfersSaveCardRequest = {
      pan: this._cardInputs.pan,
      expiration_month: parseInt(
        this._cardInputs.expDate[0] + this._cardInputs.expDate[1],
        10
      ),
      expiration_year: parseInt(
        this._cardInputs.expDate[2] + this._cardInputs.expDate[3],
        10
      ),
      cvv: this._cardInputs.cvc.toString()
    };
    return saveCardRequest;
  }

  get cardInputs(): DebitCardTransfersCardFormInput {
    return this._cardInputs;
  }

  set cardInputs(value: DebitCardTransfersCardFormInput) {
    this._cardInputs = value;
  }

  get dctCardForm(): ng.IFormController {
    return this._dctCardForm;
  }

  set dctCardForm(value: ng.IFormController) {
    this._dctCardForm = value;
  }
}

export const acpDctDisbursementCardInfoComponent: ng.IComponentOptions = {
  controller: AcpDctDisbursementCardInfoCtrl,
  controllerAs: 'vm',
  template: acpDctDisbursementCardInfoTemplate
};

export default acpDctDisbursementCardInfoComponent;
