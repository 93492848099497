import ng from 'angular';
import { AcpMedia, AcpTermsModel } from 'core';

import { AcpDctDisbursementService } from './acp-dct-disbursement-service';
import acpDctDisbursementEsignBottomSheetTemplate from './templates/acp-dct-disbursement-esign-bottom-sheet.html';
import acpDctDisbursementEsignDialogTemplate from './templates/acp-dct-disbursement-esign-dialog.html';
import acpDctDisbursementIntroTemplate from './templates/acp-dct-disbursement-intro.html';
import acpDctDisbursementTermsBottomSheetTemplate from './templates/acp-dct-disbursement-terms-bottom-sheet.html';
import acpDctDisbursementTermsDialogTemplate from './templates/acp-dct-disbursement-terms-dialog.html';

export class AcpDctDisbursementIntroComponentCtrl
  implements nsUtils.NsComponentController {
  public fee: string;
  public loadingTerms: boolean = false;
  private _loading: boolean = true;

  constructor(
    nsComponentDecorator,
    private acpTermsModel: AcpTermsModel,
    private acpDctDisbursementService: AcpDctDisbursementService,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private $mdDialog: ng.material.IDialogService,
    private acpMedia: AcpMedia,
    private $log: ng.ILogService
  ) {
    'ngInject';
    nsComponentDecorator(this, this);
  }

  public $onInit(): void {
    this.getFee().then(() => {
      this._loading = false;
    });
  }

  get loading(): boolean {
    return this._loading;
  }

  public async getFee(): Promise<void> {
    try {
      await this.acpDctDisbursementService.fetchFees();
      const feesInIntro = this.acpDctDisbursementService.fees;
      if (!feesInIntro || !feesInIntro.dct_push_transaction) {
        this.$log.debug('Fees not defined for dct_push_transaction');
      } else {
        this.fee = feesInIntro.dct_push_transaction.flat_fee;
      }
    } catch (errors) {
      throw new Error('Push Money fee is not found');
    }
  }

  public async beginDctFlow(): Promise<void> {
    if (await this.isTermsNotAccepted()) {
      this.acpDctDisbursementService.enterTerms();
    } else {
      this.acpDctDisbursementService.enterCardDetails();
    }
  }

  public openTermsPopUp() {
    this.openPopUp(
      acpDctDisbursementTermsBottomSheetTemplate,
      acpDctDisbursementTermsDialogTemplate
    );
  }

  public openEsignPopUp() {
    this.openPopUp(
      acpDctDisbursementEsignBottomSheetTemplate,
      acpDctDisbursementEsignDialogTemplate
    );
  }

  public closePopUp() {
    this.$mdDialog.hide();
    this.$mdBottomSheet.hide();
  }

  private openPopUp(bottomSheetTemplate, dialogTemplate) {
    const vm = this;
    const controllerPopUp = /*@ngInject*/ function($scope) {
      $scope.vm = vm;
      $scope.vm.host = window.location.origin;
    };
    if (this.acpMedia('mobile')) {
      this.$mdBottomSheet.show({
        clickOutsideToClose: false,
        template: bottomSheetTemplate,
        controller: controllerPopUp
      });
    } else {
      this.$mdDialog.show({
        parent: ng.element(document.body),
        template: dialogTemplate,
        controller: controllerPopUp
      });
    }
  }

  private async isTermsNotAccepted(): Promise<boolean> {
    this.loadingTerms = true;
    const result = await this.acpTermsModel.getChoicepayDisbursement();
    this.loadingTerms = false;
    return !result.accepted;
  }
}

export const acpDctDisbursementIntro: ng.IComponentOptions = {
  controller: AcpDctDisbursementIntroComponentCtrl,
  controllerAs: 'vm',
  template: acpDctDisbursementIntroTemplate
};

export default acpDctDisbursementIntro;
