import ng from 'angular';
import KeyValuePair = nsUtils.KeyValuePair;
import { AcpTermsModel } from 'core';
import { AcpDctDisbursementService } from './acp-dct-disbursement-service';
import acpDctDisbursementTermsBottomSheetTemplate from './templates/acp-dct-disbursement-terms-bottom-sheet.html';
import acpDctDisbursementTermsDialogTemplate from './templates/acp-dct-disbursement-terms-dialog.html';
import acpDctDisbursementTermsTemplate from './templates/acp-dct-disbursement-terms.html';

export class AcpDctDisbursementTermsComponentCtrl
  implements nsUtils.NsComponentController {
  private _isSubmitting: boolean = false;
  private _terms: KeyValuePair<boolean> = {
    esign: false,
    termsPp: false,
    consentToShare: false
  };
  private _termsConfirmed: boolean;
  private _today: Date = new Date();
  private _host: string = window.location.origin;

  constructor(
    private acpMedia: any,
    private $mdDialog: ng.material.IDialogService,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private acpDctDisbursementService: AcpDctDisbursementService,
    private acpTermsModel: AcpTermsModel
  ) {
    'ngInject';

    // See GB-761. Currently, life-cycle hooks do not work for nsInPageFlow.
  }

  public isInvalid(): boolean {
    return !this.allTermsAgreed();
  }

  public async submitTermsAgreement(): Promise<void> {
    if (this.allTermsAgreed()) {
      this._isSubmitting = true;

      this._termsConfirmed = true;
      await this.acpTermsModel.acceptTerms('choicepay_disbursement');

      this.acpDctDisbursementService.flowIntoCardDetails();
      this._isSubmitting = false;
    }
  }

  public openTermsPopup() {
    const vm = this;
    const controllerPopUp = /*@ngInject*/ function($scope) {
      $scope.vm = vm;
    };
    if (this.acpMedia('mobile')) {
      this.$mdBottomSheet.show({
        clickOutsideToClose: false,
        template: acpDctDisbursementTermsBottomSheetTemplate,
        controller: controllerPopUp
      });
    } else {
      this.$mdDialog.show({
        parent: ng.element(document.body),
        template: acpDctDisbursementTermsDialogTemplate,
        controller: controllerPopUp
      });
    }
  }

  public closePopUp() {
    this.$mdDialog.hide();
    this.$mdBottomSheet.hide();
  }

  get terms(): KeyValuePair<boolean> {
    return this._terms;
  }

  set terms(value: KeyValuePair<boolean>) {
    this._terms = value;
  }

  get termsConfirmed(): boolean {
    return this._termsConfirmed;
  }

  set termsConfirmed(value: boolean) {
    this._termsConfirmed = value;
  }

  get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  set isSubmitting(value: boolean) {
    this._isSubmitting = value;
  }

  get today(): Date {
    return this._today;
  }

  set today(value: Date) {
    this._today = value;
  }

  get host(): string {
    return this._host;
  }

  set host(value: string) {
    this._host = value;
  }

  private allTermsAgreed(): boolean {
    return Object.keys(this.terms).every((key) => {
      return this.terms[key] === true;
    });
  }
}

export const acpDctDisbursementTermsComponent: ng.IComponentOptions = {
  controller: AcpDctDisbursementTermsComponentCtrl,
  controllerAs: 'vm',
  template: acpDctDisbursementTermsTemplate
};

export default acpDctDisbursementTermsComponent;
